import React, {useEffect} from "react";
import {useQuery} from "@apollo/client";
import {Project} from "../../components/projects";
import {GET_PROJECTS} from "../../queries/projects";
import {Link} from "react-router-dom";
import {useTitle} from "../../hooks/use-title";
import { InlineSpinner } from '../../components/spinner'
//TODO: maybe use lazy loading
export default function List(props) {
  const { data, loading } = useQuery(GET_PROJECTS, {fetchPolicy: "no-cache"})
  const { setTitleValue, title } = useTitle();
  useEffect(() => {
    setTitleValue('Dashboard');
  }, [title, setTitleValue]);
  let projectItems = ((data && data.Projects) || []).map(project => {
    if(project && project.Versions[0]) {
      return (
        <div key={project.id} className="col-span-1">
          <Project project={project} />
        </div>
      )
    }
    return null
  })

  if(loading) {
    return <InlineSpinner />
  }

  return (
    <div>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 pb-10">
        { projectItems }
      </div>
      <Link className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" to="/project/create">Create Project</Link>
    </div>
  )
}
