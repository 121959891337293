import React, {useRef, Fragment} from 'react'
import {useRouteMatch, useLocation, useHistory} from "react-router-dom";
import { generatePath } from "react-router";
import { useQuery } from "@apollo/client";
import {GET_TRANSLATIONS} from "../../queries/translations";
import Translations from "./Translations";
import qs from 'query-string';
import Import from "./Import";
import {SmallModal} from "../../components/modals";
import TranslationForm from "./TranslationForm";
import {DefaultTabs} from "../../components/tabs";

import { Listbox } from '@headlessui/react'

function useSearchQuery() {
  return new URLSearchParams(useLocation().search);
}

function getTabs() {
  return [
    {
      label: "All",
      value: "all"
    },
    {
      label: "Untranslated",
      value: "untranslated"
    }
  ]
}

const ABC_ASC = 'abc_asc';
const ABC_DESC = 'abc_desc';
const CREATED_ASC = 'created_asc';
const CREATED_DESC = 'created_desc';
const UPDATED_ASC = 'updated_asc';
const UPDATED_DESC = 'updated_desc';

const orderings = [
  {
    id: ABC_ASC,
    name: 'Alphabetic (a to z)',
    getValue() {
      return [{key: "asc"}];
    }
  },
  {
    id: ABC_DESC,
    name: 'Alphabetic (z to a)',
    getValue() {
      return [{key: "desc"}];
    }
  },
  {
    id: CREATED_ASC,
    name: 'Created (asc)',
    getValue() {
      return [{created_at: "asc"}];
    }
  },
  {
    id: CREATED_DESC,
    name: 'Created (desc)',
    getValue() {
      return [{created_at: "desc"}];
    }
  },
  {
    id: UPDATED_ASC,
    name: 'Updated (asc)',
    getValue() {
      return [{updated_at: "asc"}];
    }
  },
  {
    id: UPDATED_DESC,
    name: 'Updated (desc)',
    getValue() {
      return [{updated_at: "desc"}];
    }
  },
]

export default function List() {
  let location = useLocation()
  let history = useHistory();
  let query = useSearchQuery();
  const tabs = getTabs();
  function setSearchQuery(q) {
    const queryParams = qs.parse(location.search);
    const newQueries = { ...queryParams, q};
    if(!q) {
      delete newQueries.q
    }
    history.replace({ search: qs.stringify(newQueries) });
  }
  function setOrdering(order) {
    const queryParams = qs.parse(location.search);
    const newQueries = { ...queryParams, order: order.id};
    history.replace({ search: qs.stringify(newQueries) });
  }
  const translationModal = useRef(null);
  let { params={}, path } = useRouteMatch();
  let title = 'title';
  const currentTab = params?.translationFilter || 'all'

  let variables = {
    projectId: params?.projectId,
    version: params?.version,
    namespace: params?.namespace,
    locale_code: params?.language,
    translation_filter: {
      Version: {name: {_eq: params?.version}},
      Namespace: {name: {_eq: params?.namespace}},
    }
  }

  if (currentTab === 'untranslated') {
    variables.translation_filter['_not'] = {
      RelatedTranslations: {
        Language: {locale_code: {_eq: params?.language}},
        _not: {value: {_eq: ''}}
      }
    }
  }

  if(params?.namespace || params?.language) {
    title = `${ params?.namespace } - ${ params?.language }`
  }

  //{_not: {Language: {locale_code: {_eq: $locale_code}}}}
  let queryKey = '';
  if(query && query.get('q')) {
    const queryFilter = `%${query.get('q')}%`;
    queryKey += queryFilter;
    variables.translation_filter['_or'] = [
      {key: {_ilike: queryFilter}},
      {value: {_ilike: queryFilter}},
      {RelatedTranslations: {
        value: {_ilike: queryFilter},
        Language: {locale_code: {_eq: params?.language}},
      }},
    ]
  }
  let selectedOrdering = orderings[0];
  if(query && query.get('order')) {
    const ordering = orderings.filter((o) => {return o.id === query.get('order')});
    if(ordering && ordering[0]) {
      queryKey += query.get('order');
      selectedOrdering = ordering[0]
      variables.order_by = ordering[0].getValue();
    }
  }
  const queryResult = useQuery(GET_TRANSLATIONS, {variables})

  const closeTranslation = () => {
    translationModal.current.close();
  }

  const isReferenceLocale = () => {
    const { Languages, reference_locale } = queryResult?.data?.Project || {};

    if(reference_locale && Languages && Languages[0]) {
      return Languages[0].locale_code === reference_locale;
    }
    return false;
  }

  return <div>
    <div
      className="pb-5 space-y-3 sm:flex sm:items-center sm:justify-between sm:space-x-4 sm:space-y-0">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{ title }</h3>
      <div className="mt-3 sm:mt-0 sm:ml-4">
        <label className="sr-only" htmlFor="search_candidate">Search</label>
        <div className="flex rounded-md shadow-sm">
          { isReferenceLocale() ? <button
            onClick={() => translationModal.current.open()}
            className="mr-2 relative inline-flex items-center px-4 py-2 text-sm text-white leading-5 font-medium rounded-md bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition ease-in-out duration-150">
            Add
          </button> : null }
          <Import queryResult={ queryResult } />
          <div className="relative flex-grow focus-within:z-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd" />
              </svg>
            </div>
            <input
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
              id="search" type="text" name="search" placeholder="Search"
              defaultValue={(query && query.get('q')) || ''}
              onChange={e => {
                setSearchQuery(e.currentTarget.value)
              }}
            />
          </div>
          <div className="relative">
            <Listbox value={selectedOrdering} onChange={setOrdering}>
              <Listbox.Button className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                </svg>
                <span className="ml-2">{selectedOrdering.name}</span>
                <svg className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd" />
                </svg>

              </Listbox.Button>
              <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg">
                <Listbox.Options className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {orderings.map(ordering => (
                    <Listbox.Option as={Fragment} key={ordering.id} value={ordering}>
                      {({ active, selected, disabled }) => {
                        let textColor = 'text-gray-900';
                        let bgColor = ''
                        let checkColor = 'text-indigo-600'
                        let cursor = 'cursor-default';
                        if(active) {
                          textColor = 'text-white';
                          bgColor = 'bg-indigo-600';
                          checkColor = 'text-white';
                        }
                        if(disabled) {
                          textColor = 'text-gray-600';
                          bgColor = 'bg-gray-200';
                          cursor = "cursor-not-allowed";
                        }
                        return (
                          <li className={`${textColor} ${bgColor} ${cursor} select-none relative py-2 pl-3 pr-9`}>
                            <span className="font-normal block truncate">
                              { ordering.name }
                            </span>
                            { selected ? <span className={`${checkColor} absolute inset-y-0 right-0 flex items-center pr-4`}>
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                   aria-hidden="true">
                <path fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"/>
              </svg>
            </span> : null }
                          </li>
                        )
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>

            </Listbox>
          </div>
        </div>
      </div>
    </div>
    <DefaultTabs
      options={ tabs }
      current={ currentTab }
      onChange={(value) => {
        const newUrl = generatePath(path, {
          ...params,
          translationFilter: value
        });

        history.replace(newUrl);
      }}
    />
    <Translations
      key={currentTab + queryKey}
      queryResult={ queryResult }
    />
    <SmallModal ref={translationModal}>
      <TranslationForm queryResult={ queryResult } close={ closeTranslation } onSuccess={() => {
        queryResult && queryResult.refetch();
        closeTranslation()
      }} />
    </SmallModal>
  </div>
}
