import React from 'react'
import { useForm } from "react-hook-form";
import {useMutation} from "@apollo/client";
import {INSERT_NAMESPACE} from "../../queries/namespaces";
import {insertNamespaceUpdate} from "../../queries/updaters";
import {InlineSpinner} from "../../components/spinner";

export default function NamespaceForm(props) {
  const { close, projectId } = props;
  const { register, handleSubmit, errors } = useForm();
  const [insertNamespace, { loading, error }] = useMutation(INSERT_NAMESPACE, {update: insertNamespaceUpdate});
  const onSubmit = async data => {
    console.log('data', data)
    try {
      const res = await insertNamespace({variables: {...data, projectId}});
      console.log('res', res);
      close && close();
    } catch (e) {
      console.log('e', e);
    }
  }

  if (loading) return <InlineSpinner />;
  if (error) return <p>An error occurred</p>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            Add a namespace
          </h3>
          <div className="text-left sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Name
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="max-w-lg flex rounded-md shadow-sm">
                <input ref={register({ required: true })} type="text" name="name" id="name" autoComplete="name" className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
              </div>
            </div>
            { errors.name ? <p className="mt-2 text-sm text-red-600" id="name-error">This field is required</p> : null }
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
          Create
        </button>
        <button onClick={() => close && close()} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
          Cancel
        </button>
      </div>
    </form>
  )
}
